import React from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Container from "../../components/Container";
import ContainerHeader from "../../components/ContainerHeader";
import ContainerContent from "../../components/ContainerContent";
import BookNowBar from "../../components/BookNowBar";
import SeoContent from "../../components/seoContent";
import { useStaticQuery, graphql } from "gatsby";
import ImageWall from "../../components/ImageWall";

export default function Availability() {
  const query = graphql`
    query {
      strapiAvailability {
        Images {
          localFile {
            childImageSharp {
              fixed(width: 800, height: 534) {
                src
              }
            }
          }
        }
        Heading
        Content
        MainImage {
          alternativeText
          localFile {
            childImageSharp {
              fixed(width: 1920, height: 1080) {
                src
              }
            }
          }
        }
        AvailabilitySeo {
          metaDescription
          metaTitle
          shareImage {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const isSingleSiteEnvSetting = process.env.IS_SINGLE_PAGE_SITE;

  const isSingleSite = isSingleSiteEnvSetting === "true";
  return (
    <>
      <SeoContent pageSeo={data.strapiAvailability.AvailabilitySeo} />
      <div className="leading-relaxed bg-primary tracking-wide flex flex-col font-custom">
        <NavBar isSingleSite={isSingleSite}/>
        <Container>
          <ContainerHeader text={data.strapiAvailability.Heading} />
          <ContainerContent text={data.strapiAvailability.Content} />
          <BookNowBar />
        </Container>
        <Container>
          <ImageWall
            images={data.strapiAvailability.Images}
            keyPrefix="availability"
          />
        </Container>
        <Footer />
      </div>
    </>
  );
}
