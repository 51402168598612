import React, { useState } from "react";

export default function BookNowBar() {
  const [date, setDate] = useState();
  const [nights, setNights] = useState();

  const submit = (e) => {
    e.preventDefault();
    var url = `https://portal.freetobook.com/reservations?w_id=26731&w_tkn=x6U7Na2JG3mgZwMk2W8MADyodWZkOANEW77arZS8CSQmhk5dJCh8WW8RRuJkG&checkIn=${date}&stayLength=${nights}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <form onSubmit={(e) => submit(e)}>
      <div className="grid grid-cols-1 py-4 md:grid-cols-8 gap-8 w-3/4 mx-auto my-22">
        <div className="col-span-3">
          <input
            className="p-4 w-full font-semibold placeholder-gray-500 border rounded-lg outline-none focus:ring-accent-blue focus:ring-1 flex-grow"
            required
            type="date"
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="col-span-3">
          <input
            className="p-4 w-full font-semibold placeholder-gray-500 border rounded-lg outline-none focus:ring-accent-blue focus:ring-1 flex-grow"
            required
            type="number"
            placeholder="Nights"
            onChange={(e) => setNights(e.target.value)}
          />
        </div>
        <div className="col-span-2">
          <button
            className="w-full p-4 text-sm tracking-wide bg-primary text-white uppercase rounded-lg shadow-xl outline-none lg:text-base bg-primary-green hover:bg-opacity-75 focus:outline-none"
            type="submit"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
}
