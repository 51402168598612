import React from "react";

export default function ImageWall(props) {
  if(!props.images)
    props.images = [];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {props.images.map((img) => {
        return (
          <div key={`${props.keyPrefix ?? "key"}-${img.id}`}>
            <img src={img.localFile.childImageSharp.fixed.src} alt={img.alternativeText} />
          </div>
        );
      })}
    </div>
  );
}
